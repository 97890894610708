import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './scss/style.scss';
import Web3 from 'web3'
import store from "./store";

import ethROTHS from './abis/ethROTHS.json'
import ethBuying from './abis/ethBuying.json'
import stakeROTHS from './abis/stakeRoths.json'
import USDTToken from './abis/USDTToken.json'
import USDCToken from './abis/USDCToken.json'
import LINKToken from './abis/LINKToken.json'
import bscBuying from './abis/bscBuying.json'
import BUSDToken from './abis/BUSD.json'
import DOTToken from './abis/DOT.json'
import bscLINKToken from './abis/bscLINK.json'



import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)
const providerOptions = {
  // Example with injected providers
  injected: {
    display: {
      // logo: "data:image/gif;base64,INSERT_BASE64_STRING",
      // name: "Injected",
      description: "Connect with the provider in your Browser"
    },
    package: null
  },
  // Example with WalletConnect provider
  walletconnect: {
    display: {
      // logo: "data:image/gif;base64,INSERT_BASE64_STRING",
      // name: "Mobile",
      description: "Scan qrcode with your mobile wallet"
    },
    package: WalletConnectProvider,
    options: {
      infuraId: "e4b98d232e614e8e8a7fe60833691cdb" // required
    }
  }
};
// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));
let account
let network
let provider
let web3
let StakeInstance
let TokenInstance
let BUSDInstance
let DOTInstance
let UNIInstance
let Buy_bscInstance
let registered
let ewall
let pstakeamount
let rstakeamount
let hist
let directCount
let referredby
let walletbalance
let stake
let reg
let transferTokens
let Supply
let Staked
let USDTInstance
let StakeAddress




const ETH = 1
const BSC = 56
class App extends Component {
  async componentDidMount() {
    await this.loadWeb3()
    await this.loadBlockchainData()
  }

  async loadWeb3() {
    const web3Modal = new Web3Modal({
      network: "mainnet", // optional
      cacheProvider: true, // optional
      providerOptions, // required
      theme: "dark",
    });

    provider = await web3Modal.connect();

  }



  async loadBlockchainData() {
    web3 = new Web3(provider)
    store.dispatch({ type: 'set', web3: web3 })

    let accounts = await web3.eth.getAccounts()
    store.dispatch({ type: 'set', account: accounts[0] })
    const networkId = await web3.eth.net.getId()

    if (networkId == ETH) {
      store.dispatch({ type: 'set', network: "ETH" })
    } else if (networkId == BSC) {
      store.dispatch({ type: 'set', network: "BSC" })
    } else {
      store.dispatch({ type: 'set', network: "" })
    }

    provider.on("disconnect", (error) => {
      console.log(error);
    });
    provider.on('accountsChanged', (accounts) => {
      store.dispatch({ type: 'set', account: accounts[0] })

      this.updateData()
    })
    provider.on('networkChanged', (networkId) => {
      if (networkId === ETH) {
        store.dispatch({ type: 'set', network: "ETH" })
      } else if (networkId === BSC) {
        store.dispatch({ type: 'set', network: "BSC" })
      } else {
        store.dispatch({ type: 'set', network: "" })
      }
      this.updateData()
    })



    const bscBuyingContract = bscBuying.networks[networkId]

    if (bscBuyingContract) {
      const bscBuyingInstance = new web3.eth.Contract(bscBuying.abi, bscBuyingContract.address)
      // this.setState({ StakeInstance })
      store.dispatch({ type: 'set', bscBuyingInstance: bscBuyingInstance })

      store.dispatch({ type: 'set', bscBuyingAddress: bscBuyingContract.address })

      // this.getRegistered()
    }

    const BUSDContract = BUSDToken.networks[networkId]

    if (BUSDContract) {
      const BUSDInstance = new web3.eth.Contract(BUSDToken.abi, BUSDContract.address)
      // this.setState({ TokenInstance })
      store.dispatch({ type: 'set', BUSDInstance: BUSDInstance })
    }

    const DOTContract = DOTToken.networks[networkId]

    if (DOTContract) {
      const DOTInstance = new web3.eth.Contract(DOTToken.abi, DOTContract.address)
      // this.setState({ TokenInstance })
      store.dispatch({ type: 'set', DOTInstance: DOTInstance })
    }

    const bscLINKContract = bscLINKToken.networks[networkId]

    if (bscLINKContract) {
      const bscLINKInstance = new web3.eth.Contract(bscLINKToken.abi, bscLINKContract.address)
      // this.setState({ TokenInstance })
      store.dispatch({ type: 'set', bscLINKInstance: bscLINKInstance })
    }

















    const ethBuyingContract = ethBuying.networks[networkId]

    if (ethBuyingContract) {
      const ethBuyingInstance = new web3.eth.Contract(ethBuying.abi, ethBuyingContract.address)
      // this.setState({ StakeInstance })
      store.dispatch({ type: 'set', ethBuyingInstance: ethBuyingInstance })

      store.dispatch({ type: 'set', ethBuyingAddress: ethBuyingContract.address })

      // this.getRegistered()
    }


    const StakeContract = stakeROTHS.networks[networkId]

    if (StakeContract) {
      const StakeInstance = new web3.eth.Contract(stakeROTHS.abi, StakeContract.address)
      // this.setState({ StakeInstance })
      store.dispatch({ type: 'set', StakeInstance: StakeInstance })
      store.dispatch({ type: 'set', StakeAddress: StakeContract.address })
      // this.getRegistered()
    }

    const TokenContract = ethROTHS.networks[networkId]

    if (TokenContract) {
      const TokenInstance = new web3.eth.Contract(ethROTHS.abi, TokenContract.address)
      // this.setState({ TokenInstance })
      store.dispatch({ type: 'set', TokenInstance: TokenInstance })
    }

    const USDCContract = USDCToken.networks[networkId]

    if (USDCContract) {
      const USDCInstance = new web3.eth.Contract(USDCToken.abi, USDCContract.address)
      // this.setState({ TokenInstance })
      store.dispatch({ type: 'set', USDCInstance: USDCInstance })
    }


    const USDTContract = USDTToken.networks[networkId]

    if (USDTContract) {
      const USDTInstance = new web3.eth.Contract(USDTToken.abi, USDTContract.address)
      // this.setState({ TokenInstance })
      store.dispatch({ type: 'set', USDTInstance: USDTInstance })
    }



    const LINKContract = LINKToken.networks[networkId]

    if (LINKContract) {
      const LINKInstance = new web3.eth.Contract(LINKToken.abi, LINKContract.address)
      // this.setState({ LINKInstance })
      store.dispatch({ type: 'set', LINKInstance: LINKInstance })
    }

    // const Buy_bscContract = Buy_bsc.networks[networkId]

    // if(Buy_bscContract) {
    //   const Buy_bscInstance = new web3.eth.Contract(Buy_bsc.abi, Buy_bscContract.address)
    //   // this.setState({ Buy_bscInstance })
    //   store.dispatch({type: 'set' , Buy_bscInstance: Buy_bscInstance})
    // } 


    //   const BUSDContract = BUSD.networks[networkId]

    // if(BUSDContract) {
    //   const BUSDInstance = new web3.eth.Contract(BUSD.abi, BUSDContract.address)
    //   store.dispatch({type: 'set' , BUSDContract: BUSDContract})
    //   // this.setState({ BUSDInstance })
    // } 

    // const DOTContract = DOT.networks[networkId]

    // if(DOTContract) {
    //   const DOTInstance = new web3.eth.Contract(DOT.abi, DOTContract.address)
    //   store.dispatch({type: 'set' ,   DOTInstance: DOTInstance})
    //   // this.setState({ DOTInstance })
    // } 

    // const UNIContract = UNI.networks[networkId]

    // if(UNIContract) {
    //   const UNIInstance = new web3.eth.Contract(UNI.abi, UNIContract.address)
    //   store.dispatch({type: 'set' , UNIInstance: UNIInstance})
    //   // this.setState({ UNIInstance })
    // } 


    // this.loadStakeData()
    this.updateData()
  }

  async getStakeHist() {
    let nos = await StakeInstance.methods.getNonce(account).call()
    let his = []
    for (var i = 1; i < nos; i++) {
      let dict = {}
      let stakeid = await StakeInstance.methods.calcStakeHash(account, i).call()
      console.log(stakeid)
      let stakemeta = await StakeInstance.methods.getStakeMeta(stakeid).call()
      dict['startTime'] = stakemeta['timestamp']
      let te = ((parseInt(stakemeta['timestamp']) + (300 * 24 * 60 * 60)) - (Date.now() / 1000))
      let tt = ((parseInt(stakemeta['timestamp']) + (300 * 24 * 60 * 60)) - stakemeta['timestamp'])
      dict['pg'] = (tt - te) / tt * 100
      dict['nonce'] = stakemeta['nonce']
      dict['stakeid'] = stakeid
      if (stakemeta['referral'] == false && stakemeta['extention'] == false) {
        dict['stakeType'] = "Normal"
      } else if (stakemeta['referral'] == false && stakemeta['extention'] == true) {
        dict['stakeType'] = "Time Lock"
        let te = ((parseInt(stakemeta['timestamp']) + (100 * 24 * 60 * 60)) - (Date.now() / 1000))
        let tt = ((parseInt(stakemeta['timestamp']) + (100 * 24 * 60 * 60)) - stakemeta['timestamp'])
        dict['pg'] = (tt - te) / tt * 100
      } else if (stakemeta['referral'] == true && stakemeta['extention'] == false) {
        dict['stakeType'] = "Referred"
      } else {
        dict['stakeType'] = "Extention"
      }
      dict['amount'] = web3.utils.fromWei(stakemeta['amount'], 'Ether')

      let claimedRewards = await StakeInstance.methods.getClaimedRewards(stakeid).call()
      dict['claimedRewards'] = web3.utils.fromWei(claimedRewards, 'Ether')
      let claimableRewards = await StakeInstance.methods.claimableStake(stakeid).call()
      dict['claimableRewards'] = web3.utils.fromWei(claimableRewards, 'Ether')


      his.push(dict)
    }
    // this.setState({hist:his})
    store.dispatch({ type: 'set', hist: his })

  }

  transferTokens = (add, amount) => {
    this.updateState()
    TokenInstance.methods.transfer(add, web3.utils.toWei(amount, 'Ether')).send({ from: account }).on('transactionHash', (hash) => {
      store.getTokenBalance()
    })
  }


  async updateData() {
    this.getTokenBalance()
    this.getRegistered()
    this.getStakeBalance()
    this.getStakedBalance()
    this.getStakeAmount()
    this.getdirectCount()
    this.getrefree()
    this.getRStakeAmount()
    this.getEWallBal()
    this.getStakeBalance()
    this.getStakeHist()

  }






  // this.updateState();
  async getTokenBalance() {
    this.updateState()
    let bal = await TokenInstance.methods.balanceOf(account).call()
    bal = web3.utils.fromWei(bal, 'Ether')
    store.dispatch({ type: 'set', walletbalance: bal })
    let app = await TokenInstance.methods.allowance(account, StakeAddress).call()
    store.dispatch({ type: 'set', approval: app })
    // walletbalance=bal
  }

  async getStakeBalance() {
    this.updateState()
    let bal = await StakeInstance.methods.getSupply().call()
    bal = web3.utils.fromWei(bal, 'Ether')
    // Supply=bal
    store.dispatch({ type: 'set', Supply: bal })
  }

  async getStakedBalance() {
    this.updateState()
    let bal = await StakeInstance.methods.getStaked().call()
    bal = web3.utils.fromWei(bal, 'Ether')
    store.dispatch({ type: 'set', Staked: bal })
    // Staked=bal
  }

  reg = (add) => {
    this.updateState()
    if (add.length > 0) {
      StakeInstance.methods.register(add).send({ from: account }).on('transactionHash', (hash) => {
        // this.setState({ register: true})
        store.dispatch({ type: 'set', register: true })
        window.location.replace("/")
      })
    } else {
      StakeInstance.methods.register().send({ from: account }).on('transactionHash', (hash) => {
        // this.setState({ register: true})
        store.dispatch({ type: 'set', register: true })
        window.location.replace("/")
      })
    }


  }



  async getStakeAmount() {
    this.updateState()
    let amt = await StakeInstance.methods.getStakeAmount(account).call()
    amt = web3.utils.fromWei(amt, 'Ether')
    store.dispatch({ type: 'set', pstakeamount: amt })
    // pstakeamount=amt
  }

  async getdirectCount() {
    this.updateState()
    let amt = await StakeInstance.methods.getDirectCount(account).call()
    store.dispatch({ type: 'set', directCount: amt })
    // directCount=amt
  }

  async getrefree() {
    this.updateState()
    let amt = await StakeInstance.methods.getrefree(account).call()
    store.dispatch({ type: 'set', referredby: amt })
    // referredby=amt
  }

  async getRStakeAmount() {
    this.updateState()
    let amt = await StakeInstance.methods.getRStakeAmount(account).call()
    amt = web3.utils.fromWei(amt, 'Ether')
    store.dispatch({ type: 'set', rstakeamount: amt })
    // rstakeamount=amt
  }

  async getEWallBal() {
    this.updateState()
    let bal = await StakeInstance.methods.getEWalletBalance(account).call()
    bal = web3.utils.fromWei(bal, 'Ether')
    store.dispatch({ type: 'set', ewall: bal })
    // ewall=bal
  }

  async getRegistered() {
    this.updateState()
    let ifRegistered = await StakeInstance.methods.getRegistered(account).call()
    store.dispatch({ type: 'set', registered: ifRegistered })
    // this.setState({registered : ifRegistered})
  }

  // async getUSDTBalance() {
  //   this.updateState()
  //   let tempBalance = await USDTInstance.methods.balanceOf(account).call()
  //   store.dispatch({type: 'set', TOKENBalance: tempBalance/1e6 })
  //   // balance = tempBalance/1e6
  // }



  updateState = () => {
    let state = store.getState()
    account = state.account
    network = state.networklet
    StakeInstance = state.StakeInstance
    TokenInstance = state.TokenInstance
    BUSDInstance = state.BUSDInstance
    DOTInstance = state.DOTInstance
    UNIInstance = state.UNIInstance
    Buy_bscInstance = state.Buy_bscInstance
    registered = state.registered
    ewall = state.ewall
    pstakeamount = state.pstakeamount
    rstakeamount = state.rstakeamount
    hist = state.hist
    directCount = state.directCount
    referredby = state.referredby
    walletbalance = state.walletbalance
    stake = state.stake
    reg = state.reg
    transferTokens = state.transferTokens
    Supply = state.Supply
    Staked = state.Staked
    USDTInstance = state.USDTInstance
    StakeAddress = state.StakeAddress
  }


  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>

            <Route path="/" name="Home" render={props => <TheLayout {...props} />} />
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
