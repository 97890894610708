import { createStore } from 'redux'

const initialState = {
  sidebarShow: 'responsive',
  asideShow: false,
  darkMode: true,
  account: '0x0',
  network: "",
  web3: {},
  StakeInstance: {},
  USDCInstance: {},
  USDTInstance: {},
  LINKInstance: {},
  TokenInstance: {},
  BUSDInstance: {},
  DOTInstance: {},
  UNIInstance: {},
  Buy_bscInstance: {},
  registered: false,
  ewall: 0,
  pstakeamount: 0,
  rstakeamount: 0,
  hist: [],
  directCount: 0,
  referredby: "",
  walletbalance: 0,
  stake: {},
  reg: {},
  transferTokens: {},
  Supply: 0,
  Staked: 0,
  TOKENBalance: 0,
  getUSDTBalance: {},
  ethBuyingInstance: {},
  ethBuyingAddress: "",
  StakeAddress: "",
  approval: 0,
  appr: 0,
  bscBuyingInstance: {},
  bscBuyingAddress: {},
  bscLINKInstance: {}
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    default:
      return state
  }
}

const store = createStore(changeState)
export default store
